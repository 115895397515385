<template>
<div class="alarm-rule-container">
  <div class="config-tree-area">
    <GroupTree />
  </div>
  <div class="device-data-area">
    <div class="query-data-area">
      <span class="ml10">{{showLang('com.tab.title')}}：</span>
      <Input type="text" v-model="filter.name" clearable :placeholder="showLang('com.device.pole.name')" style="width: 200px; margin-right: 10px;"></Input>
      通信ID：
      <Input type="text" v-model="filter.code" clearable placeholder="通信ID" style="width: 200px; margin-right: 10px;"></Input>
      <Button size="default" type="primary" @click="search" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
      <AuthButton opCode='pld' size="default" type="error" @click="deleteParams">批量删除</AuthButton>
    </div>
    <div class="alarm-data-area" ref="table1">
      <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source
          ref="chkTable" :key="dataRefresh" :data="list" height="auto" stripe
          :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',highlight: true}" >
        <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
        <vxe-column field="name" title="名称"></vxe-column>
        <vxe-column field="code" title="设备通信ID"></vxe-column>
        <vxe-column field="poleName" title="所在灯杆"></vxe-column>
        <vxe-column field="groupName" title="所在分区"></vxe-column>
        <vxe-column field="connectTypeName" title="接入方式" width="150"></vxe-column>
        <vxe-column field="width" width="100" title="宽度"></vxe-column>
        <vxe-column field="height" width="100" title="高度"></vxe-column>
        <vxe-column field="buildDateName" title="安装日期" width="120"></vxe-column>
        <vxe-column width="150" title="操作" fixed="right">
          <template #header>
            <Button v-if="funCodes(1801)" size="small" type="primary" @click="handleAdd" style="margin-right: 5px">新增</Button>
            <Button v-if="funCodes(1800)" size="small" type="primary" @click="getList" style="margin-right: 5px">刷新</Button>
          </template>
          <template #default="params">
            <Button v-if="funCodes(1802)" size="small" type="info" @click="handleEdit(params)" style="margin-right: 5px">编辑</Button>
            <Button v-if="funCodes(1803)" size="small" type="error" @click="handleDelete(params)" style="margin-right: 5px">删除</Button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
    <ModalItemEdit v-model="showEditModal" :item="editItem" :connectTypes="connectTypes" @saved="itemSaved" />
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalItemEdit from './ModalItemEdit'
import GroupTree from '../../common/treeGroup/Index'
export default {
  name: 'ConfigLedIndex',
  components:{
    ModalItemEdit,
    GroupTree,
  },
  props: {
  },
  data () {
    return {
      list: [],
      total: 0,
      editItem: {isAdd: true, data: {}},
      showEditModal: false,
      filter: {
        groupId: 0,
        name: '',
        code: '',
        index: 1,
        size: 20
      },
      connectTypes: [],
      dataRefresh: 0,
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('common', ['pageLayouts', 'pageSizes']),
    ...mapState('group', ['groups', 'groupTreeSelectedNode']),
  },
  watch: {
    groupTreeSelectedNode(){
      this.search();
    },
  },
  mounted: function(){
    this.getConnectTypes();
  },
  destroyed: function(){
  },
  methods: {
    search: function(){
      this.filter.index = 1
      this.getList()
    },
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.getList()
    },
    getCheckedItems: function(){//查询所有被选中的记录
      return this.$refs.chkTable.getCheckboxRecords();
    },
    handleAdd: function(){
      this.editItem = {isAdd: true, data: {}};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showEditModal = true;
    },
    itemSaved: function(){
      this.getList();
    },
    deleteParams: function(){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if(chks.length == 0){
        this.$Message.warning('请选择要删除的记录');
        return;
      }
      // console.log('get selected', chks)
      let list = chks.map(p => p.id);
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除已选的[${list.length}]条记录吗?`,
        onOk: async () => {
          this.$axios.post(`device/led/DeleteLedInfo`, {list: list}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.getList();
            }
          });
        }
      });
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除[${params.row.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`device/led/DeleteLedInfo`, {list: [params.row.id]}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.getList();
            }
          });
        }
      });
    },
    getList: function(){
      this.filter.groupId = this.groupTreeSelectedNode.id;
      this.$axios.post(`device/led/QueryConfigList`, this.filter).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.total = res.data.count;
          this.$set(this, 'list', res.data.list);
          this.dataRefresh++;
        }
      });
    },
    getConnectTypes: function(){
      this.$axios.post(`device/led/GetConnectionTypes`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'connectTypes', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
.alarm-rule-container{
  height: 100%;
  display: flex;
  /* flex-direction: column;
  align-content: stretch; */
  /* padding: 10px; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.config-tree-area{
  border: solid 1px #dcdee2;
  width: 300px !important;
  flex: none;
  border-radius: 6px;
}
.device-data-area {
  margin-left: 10px;
  border: solid 1px #dcdee2;
  width: 300px;
  flex: auto;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
    background: #fff;
}
.query-data-area {
  border-bottom: solid 1px #dcdee2;
  align-items: center;
  /* margin: 5px 0; */
  padding: 5px 0;
  height: 45px;
  flex: none;
}
.alarm-data-area{
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  /* padding-left: 10px; */
}
.station-alarm-tabs{
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab{
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active{
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent{
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
</style>